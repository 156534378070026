import Vue from "vue";

function initialState() {
  return {
    step                  : "LoginEntrar",
    currentTransition     : 'next',
    dados                 : { },
    dialog_flutuante      : false,
  }
}

var vm_store_Login = new Vue({
  data () {
    return initialState();
  },

  methods: {

    resetData() { 
      Object.assign(this.$data, initialState());
    },
  }
    
})

export default vm_store_Login

