<template>
  <v-card class="top-bar overflow-hidden" elevation="0" height="" tile>
    <v-app-bar :color="COR_PRINCIPAL" dark class="pr-">

      <!-- Botao lateral do Menu abre ao passar o mouse quando for Desktop ----------------------->
      <v-app-bar-nav-icon v-if="store_usuario.autenticado && !isMobile" class="d-flex toogle-icon"
        @mouseover="drawer = true"
        height="400px"
      ></v-app-bar-nav-icon>
      <!-- Botao lateral do Menu abre ao passar ao clicar quando for Mobile ---------------------->
      <v-app-bar-nav-icon v-if="store_usuario.autenticado && isMobile" class="d-flex toogle-icon"
        @click="drawer = true"
        height="400px"
      ></v-app-bar-nav-icon>

      <v-toolbar-title class="title-toolbar mr-n16 pl-2">
        <img class="img-toolbar" src="../assets/younect_vertical.png"/>
      </v-toolbar-title>

      <v-spacer></v-spacer>

<!-- {{store_usuario.user.cod_empresa }} / 
{{store_site.cod_empresa }} -->
      <v-btn icon>
        <v-icon>mdi-email</v-icon>
      </v-btn>
      <v-btn icon>

        <v-icon>mdi-bell</v-icon>
      </v-btn>

        <v-btn
          v-if="!store_usuario.autenticado"
          class="action-button text-none btn-login-mobile ml-4"
          color="#A0A0A0"
          dark
          outlined
          v-on:click="store_Login.currentTransition='prev'; store_Login.step='LoginEntrar'; store_Login.dialog_flutuante=true"
        >
          <v-icon class="mr-3">
            mdi-login
          </v-icon>
          <small
            style="display: block; font-size: 14px !important; color: #e0e0e9"
          >
            Entrar
          </small>
        </v-btn>

        <!-- NOVO MODAL USANDO VUETIFY -->
          <v-dialog
            v-model="store_Login.dialog_flutuante"
            max-width="440px"
            :retain-focus="false"
            class=""
          >
            <v-card class="justify-end align-center">
              <LoginContainerFlutuante ref="LoginContainerFlutuante" />
            </v-card>
          </v-dialog>
        <!-- NOVO MODAL USANDO VUETIFY -->

      <!-- Avatar lado direito com Menu -->
      <div v-if="store_usuario.autenticado" class="ml-3">
        <v-menu offset-y open-on-hover         
           class="menu-login">
          <template v-slot:activator="{ on }">
            <v-avatar color="#909090" size="44" v-on="on">
              <v-avatar
                v-if="store_usuario.user.foto"
                size="42"
                rounded="false"
                style="top: 0px; left: 0px"
              >
                <v-img v-bind:src="store_usuario.user.foto"></v-img>
              </v-avatar>
              <v-avatar
                v-else
                color="#D0D0D0"
                rounded="false"
                size="42"
                style="top: 1px; left: 1px"
              >
                <span color="#274ABB" class="headline">{{
                  store_usuario.user.pessoa_nome_iniciais
                }}</span>
              </v-avatar>
            </v-avatar>
          </template>

          <v-card>
              <v-list-item-content class="justify-center">
                <div class="mx-auto text-center ml-2 mr-2">
                  <v-avatar color="#909090" size="44" class="mb-2">
                    <v-avatar
                      v-if="store_usuario.user.foto"
                      size="42"
                      rounded="false"
                      style="top: 0px; left: 0px"
                    >
                      <v-img v-bind:src="store_usuario.user.foto"></v-img>
                    </v-avatar>
                    <v-avatar
                    v-else
                    color="#D0D0D0"
                    rounded="false"
                    size="42"
                    style="top: 1px; left: 1px"
                    >
                      <span color="#274ABB" class="headline">{{
                      store_usuario.user.pessoa_nome_iniciais
                    }}</span>
                    </v-avatar>
                  </v-avatar>
                  <h3 class="full-name">{{ store_usuario.user.pessoa_nome }}</h3>
                  <p class="text-caption mt-1">
                    {{ store_usuario.user.email }}
                  </p>

                  <v-divider class="my-3"></v-divider>

                  <!-- <v-btn depressed rounded text @click="dialogEditarConta = true"> Editar Conta </v-btn> -->

                  <!-- <v-divider class="d-none my-3"></v-divider> -->

                <v-list>
                  <v-list-item
                    v-for="(item, i) in items"
                    :key="i" >
                    <v-list-item-content>
                      <v-divider v-if="item.title == '-'"></v-divider>
                      <v-btn v-else
                        depressed
                        class="text-none"
                        @click="selectSection(item)">
                        <v-icon class="mr-3" v-text="item.icon"></v-icon>{{item.title}}
                      </v-btn>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>


                </div>
              </v-list-item-content>
          </v-card>
        </v-menu>
        </div>
        <!-- FIM Avatar lado direito com Menu -->

    </v-app-bar>

    <!-- Menu Lateral baseado nos menus que o usuario possui privilegios --------------------- -->
    <div v-if="store_usuario.autenticado" @mouseleave="drawer = false">
      <v-navigation-drawer v-model="drawer"
        app
        clipped
        :mini-variant="!isMobile"
        :expand-on-hover="!isMobile"
        :fixed="!isMobile"
        :temporary="!isMobile"
        :open-on-hover="!isMobile"
        :color="COR_SECUNDARIA"
        style="width:300px !important">
        <v-list-item >
                           <!-- :expand-on-hover="$vuetify.breakpoint.mdAndUp"
                           :value="$vuetify.breakpoint.smAndDown? drawer : true" -->
          
          <v-list-item-avatar>
            <v-avatar color="#909090" size="44">
              <v-avatar
                v-if="store_usuario.user.foto"
                size="42"
                rounded="false"
                style="top: 0px; left: 0px"
              >
                <v-img v-bind:src="store_usuario.user.foto"></v-img>
              </v-avatar>
              <v-avatar
                v-else
                color="#D0D0D0"
                rounded="false"
                size="42"
                style="top: 1px; left: 1px"
              >
                <span color="#274ABB" class="headline">{{
                  store_usuario.user.pessoa_nome_iniciais
                }}</span>
              </v-avatar>
            </v-avatar>
          </v-list-item-avatar>

          <v-list-item-content>
            <v-list-item-title>{{store_usuario.user.pessoa_nome}}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-divider></v-divider>
        
        <!-- -- Menus ------------------------- -->
        <v-list nav
                dense>
          <v-list-group no-action v-for='navLink in store_usuario.menu_itens' :key="navLink.title" :append-icon="navLink.subLinks ? '$expand' : null">
            
            <!-- 1 Nivel com ou sem link -->
            <v-list-item no-action slot='activator' :to="!navLink.subLinks ? {path: navLink.path, params: {param: '2'}} : null">
              <v-list-item-icon no-action>
                  <v-icon no-action>{{ navLink.icon }}</v-icon>
              </v-list-item-icon>
              <v-list-item-title><span>{{ navLink.title }}</span></v-list-item-title>
            </v-list-item>

            <!-- 2 Nivel com link -->
            <v-list-item class="ml-n5" router exact v-for='sub in navLink.subLinks' :key="sub.title" @click="abreLink(sub.path)">
              <v-list-item-icon>
                  <v-icon>{{ sub.icon }}</v-icon>
              </v-list-item-icon>
              <v-list-item-title>{{ sub.title }}</v-list-item-title>
            </v-list-item>

          </v-list-group>
        </v-list>
        <!-- FIM Menus ------------------------- -->

      </v-navigation-drawer>
    </div>

    <!-- DIALOG --------------------------------------------------------------->
    <v-dialog
      v-model="dialogEditarConta"
      max-width="440"
    >
      <v-card>
      <EditarConta :dialog_EditarConta.sync="dialogEditarConta" />
      </v-card>
    </v-dialog>

  </v-card>
</template>

<script>
import store_usuario from "../store/store_usuario";
import store_site from "../store/store_site";
import store_Login from "./Login/store_Login";
import { logOut } from "../services/autenticacao";
import LoginContainerFlutuante from "./Login/LoginContainerFlutuante";
import EditarConta from "./Login/EditarConta";
import { COR_PRINCIPAL, COR_SECUNDARIA, COR_SECUNDARIA_2, COR_BACKGROUND } from "../services/constantes";

export default {
    components: {
    LoginContainerFlutuante,
    EditarConta
  },

  data: () => ({
    ddd               : false,
    drawer            : false,
    group             : null,
    store_Login       : store_Login,
    store_usuario     : store_usuario,
    store_site        : store_site,

    dialogEditarConta : false,

    COR_PRINCIPAL     : COR_PRINCIPAL,
    COR_BACKGROUND    : COR_BACKGROUND,
    COR_SECUNDARIA    : COR_SECUNDARIA,
    COR_SECUNDARIA_2  : COR_SECUNDARIA_2,

    items: [
        // { title: "Meu Perfil", icon: "mdi-login" },
        // { title: "Minhas Negociações", icon: "mdi-account-cash-outline" },
        // { title: "-" },
        { title: "Sair", icon: "mdi-close" },
    ],

  }),

  // Alimenta as lista dos privilégios -------------------
  async mounted() {
    this.store_usuario.aplicacao_evento = await this.store_usuario.busca_aplicacoes();
    this.store_usuario.empreend_evento  = await this.store_usuario.busca_empreendimentos();
    this.store_usuario.painel_evento    = await this.store_usuario.busca_paineis();
    this.store_usuario.relatorio_evento = await this.store_usuario.busca_relatorios();
  },

  computed:{
    isMobile() {
      return this.$vuetify.breakpoint.name==='xs'
    },
  },

  methods: {
    abreLink(link) {
      this.$router.push({path: link}).catch(() => {});

      //foi comentado por causa dos parametro que eram passados nos links do segundo nivel - precisa verificar se vai precisar
      // this.$router.push({path: link, params: link, query: link}).catch(() => {});
    },

    selectSection(item) {
      // Menu Meu Perfil ------------------------
      this.selectedSection = item;
      if (this.selectedSection.title == "Meu Perfil") {
        this.dialogEditarConta = true
      }
      // Menu Sair ------------------------------
      else if (this.selectedSection.title == "Sair") {
        store_Login.dialog_flutuante = false;
        logOut();
        const path = "/";
        if (this.$route.path !== path) this.$router.push("/");
      } else if (this.selectedSection.title == "Minhas Negociações") {
        this.$router.push("favoritos");
      } else if (this.selectedSection.title == "Dashboard") {
        this.$router.push("dashboard");
      } else if (this.selectedSection.title == "Relatórios") {
        this.$router.push("relatorios");
      }
    },
  },
  
};

</script>

<style scoped>
.top-bar {
  width: 100%;
  position: sticky;
  top: 0px;
  z-index: 10;
}

.top-bar:after {
  content: '';
  width: 100%;
  display: block;
  height: 0.5px;
  background: #82a4be!important;
  opacity: 0.9 !important;
}

.title-toolbar {
  width: 70px;
}

.img-toolbar {
  width: 100%;
  height: auto;
  display: block;
}

@media(max-width: 599px) {

  .img-toolbar {
  width: 50px;
  height: auto;
  display: block;
}

.col-links.ml-16 {
  margin-left: 0px!important;
}
}
.full-name {
  font-size: 19px;
}

.nav-link {
  font-weight: 370 !important;
  font-size: 16px !important;
  color: #fff !important;
  opacity: 0.7;
  transition: 0.3s;
}

.nav-link:hover {
  color: #fff !important;
  opacity: 1;
}

.toogle-icon {
    opacity: .6;
}

</style>