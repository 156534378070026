<template>
	<div name="VueCropImage">

    <v-row class="child-flex">
      <div class="content">

            <!-- foto -------------------------------------------------- -->
            <input
              ref="input"
              type="file"
              name="image"
              accept="image/*"
              @change="setImage"
            />
            <input
              ref="inputwebcam"
              type="file"
              name="image"
              accept="image/*"
              capture="camera"
              @change="setImage"
            />
            <v-layout justify-center>
              <div >
                <cropper
                  style="width:300px; height:300px"
                  ref="cropper"
                  :src="imgcrop"
                  @change="onChange"
                  :debounce="false"
                  stencil-component="circle-stencil"                
                  adjustStencil="true"
                  :stencil-props="{
                    handlers: {},
                    movable: false,
                    resizable: false,
                    aspectRatio: 1,
                  }"
                  :stencil-size="{
                    width : 300,
                    height: 300,
                  }"
                  image-restriction="fit-area"
                />
              </div>

              <!-- <cropper
                class="cropper"
                ref="cropper"
                :src="imgcrop"
                :width="a1"
                :height="a2"
                stencil-component="circle-stencil"                
                adjustStencil="true"
                image-restriction="fit-area"
                :stencil-props="{
                  aspectRatio: 1/1,
                  previewClass: 'preview'
                }"
                @change="change"
              /> -->
            </v-layout>
          </div>

          <!-- botoes de Zoom -------------------------------------------------- -->
          <div >
            <v-layout justify-space-around>
              <v-slider
                style="margin-bottom:-15px; margin-top:15px"
                v-model="zoom_slide"
                append-icon="mdi-magnify-plus-outline"
                prepend-icon="mdi-magnify-minus-outline"
                @change="zoom_slider()"
                :thumb-size="24"
                 thumb-label="always"
              ></v-slider>
            </v-layout>
    
            <!-- botoes de Webcam, Arquivo etc -------------------------------------------------- -->
            <v-layout justify-space-around class="mt-0">
              <v-btn
                v-if="isMobile"
                v-on:click="webcamMobile()"
                dark
                primary
                color="primary"
                fab><v-icon class="eye-icon">mdi-webcam</v-icon>
              </v-btn>
              <v-btn
                v-if="!isMobile && possuiWebCam"
                v-on:click="dialog_webcam = true"
                dark
                primary
                color="primary"
                fab><v-icon class="eye-icon">mdi-webcam</v-icon>
              </v-btn>
              <v-btn
                v-on:click="arquivo()"
                dark
                primary
                color="primary"
                fab><v-icon class="eye-icon">mdi-file-image</v-icon>
              </v-btn>
              <v-btn
                v-on:click="limpar()"
                dark
                primary
                color="red"
                fab><v-icon class="eye-icon">mdi-delete</v-icon>
              </v-btn>                        
            </v-layout>
            <div class="mt-4 mb-4" style="height:0px; border-top: 1px solid #F0F0F0" ></div>
            <!-- botoes de salvar e cancelar -------------------------------------------------- -->
            <v-layout justify-space-around >
              <v-btn
                v-on:click="cancelar()"
                id="btn_cancelar"
                ref="btn_cancelar"
                class="mr-4"
                color="primary"
                text
                outlined
              >
                Cancelar
              </v-btn>

              <v-btn
                v-on:click="cropimage()"
                class="btn white--text"
                color="primary accent-4"
              >
                Confirmar
              </v-btn>
            </v-layout>
          </div>

            <v-dialog v-model="dialog_webcam" max-width="440" :retain-focus="false">
              <v-card class="justify-end align-center">
                <Cam ref="Cam"
                     :imgcrop.sync="imgcrop"
                     :dialog_webcam.sync="dialog_webcam"/>
              </v-card>
            </v-dialog>

    </v-row>
  </div>  

</template>


<script>

import { Cropper } from 'vue-advanced-cropper'
import 'vue-advanced-cropper/dist/style.css';
import { resizeImage, blobToBase64 } from "../../../services/funcoes"
import { IMG_BRANCO } from "../../../services/constantes"
import Cam from "../WebCam/Cam.vue";

export default {
  name : "VueCropImage",

	components: {
		Cropper,
    Cam
	},

  props : ['img', 'dialog_crop'],

  data() {
    return {
      imgcrop           : null,
      zoom_slide        : 1,
      zoom_slide_Aux    : 1,
      zoom_img          : 0,

      isMobile          : false,
      possuiWebCam      : false,

      resizedImg        : null,

      dialog_webcam     : false,

			result: {
				coordinates: null,
				image: null
			}
	  }
  },

  created() {
    // device detection
    if(/(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|ipad|iris|kindle|Android|Silk|lge |maemo|midp|mmp|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows (ce|phone)|xda|xiino/i.test(navigator.userAgent) 
    || /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(navigator.userAgent.substr(0,4))) { 
      this.isMobile = true;
    }
    if (!this.isMobile) {
      this.video = this.$refs.video;
      if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
        navigator.mediaDevices.getUserMedia({ video: true }).then((stream) => {
          this.possuiWebCam = true;
        }).catch(() => {
          console.log('Não encontrou camera')  
        })
      }
      else {
        console.log('Não encontrou cameras')
      }
    }

    this.imgcrop = IMG_BRANCO;
    if (this.img)
      this.imgcrop = this.img;
  },

  beforeDestroy() {
    //console.log('beforeDestroy')
  },

  destroyed() {
    //console.log('DESTROYED !!')
  },

  unmounted() {
    //console.log('UNMOUNTED !!')
  },

  mounted() {
    //console.log('MONTOU !!')
  },

  methods: {

		onChange({ coordinates, image }) {
			 this.result = {
			 	coordinates,
			 	image
			 };
		},

		change({ coordinates, canvas }) {
			//console.log(coordinates, canvas)
		},
    
    defaultSize({ imageSize, visibleArea }) {
			return {
				width: (visibleArea || imageSize).width,
				height: (visibleArea || imageSize).height,
			};
    },

    // ------------------------------------

    fecha() {
      this.$emit('update:dialog_crop', false)
    },

    arquivo() {
      this.showFileChooser();
    },

    webcamMobile() {
      this.showWebCam();
    },

    limpar() {
      var img = IMG_BRANCO
      this.imgcrop = img;
    },

    cancelar() {
      this.$emit('update:dialog_crop', false);
    },

    async cropimage() {
      if (this.imgcrop == IMG_BRANCO) {
        this.$emit('update:imgsync', null);
        this.$emit('update:dialog_crop', false);
      }
      else {
        var result = this.$refs.cropper.getResult();
        result = result.canvas.toDataURL("image/jpg")
        resizeImage({ file: result, maxSize: 200 }).then(async (resizedImage) => {
          const ls_Foto = await blobToBase64(resizedImage);
          this.$emit('update:imgsync', ls_Foto);
          this.$emit('update:dialog_crop', false);
        }).catch((err) => {
          console.error("[ERRO] : ", err);
        });
      }

    },

    getCropBoxData() {
    },

    getData() {
    },

    move(offsetX, offsetY) {
    },

    reset() {
    },

    rotate(deg) {
    },

    setCropBoxData() {
    },

    setData() {
    },

    setImage(e) {
      const file = e.target.files[0];

      if (file.type.indexOf('image/') === -1) {
        alert('Please select an image file');
        return;
      }

      if (typeof FileReader === 'function') {
        const reader = new FileReader();

        reader.onload = (event) => {
          this.imgcrop = event.target.result;
        };

        reader.readAsDataURL(file);
      } else {
        alert('Sorry, FileReader API not supported');
      }
    },

    showFileChooser() {
      this.$refs.input.click();
    },

    showWebCam() {
      this.$refs.inputwebcam.click();
    },

    zoom_slider () {
      if (this.zoom_slide > this.zoom_slide_Aux)
        this.zoom_img     = 1 + (this.zoom_slide/100)
      else if (this.zoom_slide < this.zoom_slide_Aux)
        this.zoom_img     = 0 + (this.zoom_slide/100)
      this.zoom_slide_Aux = this.zoom_slide;
      this.$refs.cropper.zoom(this.zoom_img)
    },

	},
  
}

</script>

<style scoped>
  .cropper {
    width: 350px;
    height: 350px;
    background: #DDD;
  }
  
  .preview {
	  border: dashed 1px rgba(255,255,255, 0.25);
  }

  body {
    font-family: Arial, Helvetica, sans-serif;
    width: 1024px;
    margin: 0 auto;
  }

  input[type="file"] {
    display: none;
  }

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 0 5px 0;
  }

  .header h2 {
    margin: 0;
  }

  .header a {
    text-decoration: none;
    color: black;
  }

  .content {
    display: flex;
    justify-content: space-between;
  }

  textarea {
    width: 100%;
    height: 100px;
  }

</style>
