import Vue from "vue";
import { API } from "../../services/API"
import { maskCPFCNPJ, maskTELEFONE, CpfCnpjIsValid, emailIsValid, telefoneIsValid } from "../../services/funcoes";

function initialState() {
  return {
    dados       : [],
    paginas     : 0,
    count_dados : null,

  }
}

var vm_store_Usuarios = new Vue({

  data () {
    return initialState();
  },

  methods: {

    resetData() { 
      Object.assign(this.$data, initialState());
    },

    async UsuariosGet(p_params) {
      // var lo_Params;
      // if (p_codUsuario)
      //     lo_Params = {cod_usuario : p_codUsuario}
      // else if (p_filtro)
      //     lo_Params = {filtro : p_filtro}
      // else
      //     lo_Params = {}

      const resposta = await API.get("usuario", {
                                                  params: {
                                                      ...p_params
                                                  },
                                                  }
                                      );
      if (resposta) {
        if (resposta.data.result != 'Não possui dados') {
          if (p_params && (p_params.cod_usuario || p_params.cod_funcao)) {
            //console.log("usuarios -- 2 ", resposta.data.result);
            //this.usuario_selecionado = resposta.data.result;
            return resposta.data.result
          }
          else {
            this.dados   = resposta.data.result.rows;
            this.paginas = resposta.data.result.paginas;
          }
        }
        else
          this.dados = [];
      }
    },

  }
    
})

export default vm_store_Usuarios
