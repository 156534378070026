<template>
  <v-app id="inspire">

    <!-- Alerta geral de indicacao de mensagens de sucesso ou erro ------------------ -->
    <v-snackbar
      v-model="store_site.alert"
      :timeout="store_site.alert_timeout"
      :color="store_site.alert_cor"
      :centered="false"
      :bottom="false"
      :top="true"
      max-width="400px"
      max-height="400px"
      :multi-line="true"
      :vertical="true"
      elevation="5"
      :light="false"
      :outlined="false"
      rounded="5"
      :shaped="false"
    >
      {{store_site.alert_msg}}
      <template v-slot:action="{ attrs }">
        <v-btn
          text
          v-bind="attrs"
          @click="store_site.alert = false"
          class="text-none"
          dark
        >
          <br>
          Fechar
        </v-btn>
      </template>
    </v-snackbar>

    <!-- overlay global para loadind -->
    <v-overlay
      class="align-center justify-center"
      :opacity="0.5"
      :value="store_site.overlay_global"
      z-index="100"
    >
      <v-progress-circular
        :size="64"
        :color="COR_PRINCIPAL"
        indeterminate
      ></v-progress-circular>
    </v-overlay>

    <NavbarLogado v-if="store_usuario.autenticado && !store_site.ViscomSGI"/>

    <v-main>
      <router-view />
    </v-main>
  </v-app>
</template>

<script>
import NavbarLogado from "./components/NavbarLogado.vue";
//import BotaoFavoritos from "./components/BotaoFavoritos/BotaoFavoritos.vue";
import store_site from "./store/store_site";
import store_usuario from "./store/store_usuario";
import store_Login from "./components/Login/store_Login";
import {  COR_PRINCIPAL,
          COR_SECUNDARIA,
          COR_BACKGROUND,
          COR_SUBTITULO,
          COR_SOMBRA,
          COR_BORDA,
          COR_PLACEHOLDER,
          COR_BACKGROUND_TEXT } from "./services/constantes"
import { logOut } from "./services/autenticacao";
//import { Plugins } from '@capacitor/core'
import { SplashScreen } from '@capacitor/splash-screen';

//import LoginContainerFlutuante from "./components/Login/LoginContainerFlutuante";
//import ModalCorretorParceiro from "./components/ModalParceria/ModalCorretorParceiro.vue";
//import ModalEmpreendedorParceiro from "./components/ModalParceria/ModalEmpreendedorParceiro.vue";

export default {
  data: () => ({
    drawer: null,
    //        items: [
    //   { title: 'Dashboard', icon: 'mdi-view-dashboard' },
    //   { title: 'Usuário', icon: 'mdi-image' },
    //   { title: 'Sobre', icon: 'mdi-help-box' },
    // ],
    items: [
      /* { title: "Meu Perfil" }, */
      /* { title: "Minhas Negociações" }, */
      /* { title: "Dashboard" }, */
      /* { title: "Relatórios" }, */
      { title: "Sair" },
    ],
    drawer              : false,
    group               : null,
    store_site          : store_site,
    store_usuario       : store_usuario,
    store_Login         : store_Login,

    COR_PRINCIPAL       : COR_PRINCIPAL,
    COR_SECUNDARIA      : COR_SECUNDARIA,
    COR_BACKGROUND      : COR_BACKGROUND,
    COR_SUBTITULO       : COR_SUBTITULO,
    COR_SOMBRA          : COR_SOMBRA,
    COR_BORDA           : COR_BORDA,
    COR_PLACEHOLDER     : COR_PLACEHOLDER,
    COR_BACKGROUND_TEXT : COR_BACKGROUND_TEXT,

    dialogCorretor      : false,
    dialogEmpreendedor  : false,
  }),

  components: {
    NavbarLogado,
    //BotaoFavoritos,
    //LoginContainerFlutuante,
    //ModalCorretorParceiro,
    //ModalEmpreendedorParceiro,
  },

  created() {
    document.documentElement.style.setProperty('--COR_PRINCIPAL', this.COR_PRINCIPAL);
    document.documentElement.style.setProperty('--COR_SECUNDARIA', this.COR_SECUNDARIA);
    document.documentElement.style.setProperty('--COR_BACKGROUND', this.COR_BACKGROUND);
    document.documentElement.style.setProperty('--COR_SUBTITULO', this.COR_SUBTITULO);
    document.documentElement.style.setProperty('--COR_SOMBRA', this.COR_SOMBRA);
    document.documentElement.style.setProperty('--COR_PLACEHOLDER', this.COR_PLACEHOLDER);
    document.documentElement.style.setProperty('--COR_BACKGROUND_TEXT', this.COR_BACKGROUND_TEXT);
    document.documentElement.style.setProperty('--COR_BORDA', this.COR_BORDA);

    // var scale = 'scale(0.9)';
    // document.body.style.webkitTransform =  scale;    // Chrome, Opera, Safari
    // document.body.style.msTransform =   scale;       // IE 9
    // document.body.style.transform = scale;     // General    

  },

  mounted() {
    SplashScreen.hide();
  },

  methods: {
    selectSection(item) {
      this.selectedSection = item;
      if (this.selectedSection.title == "Sair") {
        store_Login.dialog_flutuante = false;
        logOut();
        const path = "/";
        if (this.$route.path !== path) this.$router.push("/");
      } else if (this.selectedSection.title == "Minhas Negociações") {
        this.$router.push("favoritos");
      } else if (this.selectedSection.title == "Dashboard") {
        this.$router.push("dashboard");
      } else if (this.selectedSection.title == "Relatórios") {
        this.$router.push("relatorios");
      } else if (this.selectedSection.title == "Configurações") {
        this.$router.push("configuracoes");
      }
    },

    // SnackbarShow(type) {
    //   if (!type) return;
    //   switch (type) {
    //     case "error":
    //       this.snackbar = {
    //         color: "error",
    //         icon: "error",
    //         mode: "multi-line",
    //         position: "top",
    //         timeout: 7500,
    //         title: "Error",
    //         text: "Something's gone wrong, sorry.",
    //         visible: true
    //       };
    //       break;
    //     case "info":
    //       this.snackbar = {
    //         color: "info",
    //         icon: "info",
    //         mode: "multi-line",
    //         position: "top",
    //         timeout: 0,
    //         title: "Information",
    //         text:
    //           "This is useful and is quite a long message, and won't be hidden automatically. You need to dismiss this by clicking the 'X' on the right.",
    //         visible: true
    //       };
    //       break;
    //     case "success":
    //       this.snackbar = {
    //         color: "success",
    //         icon: "check_circle",
    //         mode: "multi-line",
    //         position: "top",
    //         timeout: 7500,
    //         title: "Success",
    //         text: "That worked, hoorah.",
    //         visible: true
    //       };
    //       break;
    //     case "warning":
    //       this.snackbar = {
    //         color: "warning",
    //         icon: "warning",
    //         mode: "multi-line",
    //         position: "top",
    //         timeout: 7500,
    //         title: "Warning",
    //         text: "You probably shouldn't have seen that, oops.",
    //         visible: true
    //       };
    //       break;
    //   }
    // }

  },

};

/* Esconde a sistem-bar ao scroll down; Mostra a sistem-bar ao scroll up */
// var prevScrollpos = window.pageYOffset;
// window.onscroll = function() {
//   var x = window.matchMedia("(max-width: 768px)");
//   var currentScrollPos = window.pageYOffset;
//   if (x.matches) {

//     document
//       .getElementById("header")
//       .setAttribute("style", "margin-top: 0px !important; width: 100%;");
//   } else if (prevScrollpos > currentScrollPos) {
//     document
//       .getElementById("sistem-bar")
//       .setAttribute("style", "top: 0; transition: 0.3s;");
//     document
//       .getElementById("header")
//       .setAttribute("style", "margin-top: 30px !important; width: 100%;");

//     document
//       .getElementById("container-logo")
//       .setAttribute(
//         "style",
//         " margin-top: -35px; margin-left: -16px; background: #eceff1; background: #274abb; background: #012441; width: 180px; height: 95px; border-right: 1px solid #324C60 !important;"
//       );
//     if (document.getElementById("avatar-menu"))
//       document
//         .getElementById("avatar-menu")
//         .setAttribute(
//           "style",
//           "position: absolute; top: -20px; right: 15px; width: 36px; height: 36px; transition: 0.3s;"
//         );
//     if (document.getElementById("exit"))
//       document
//         .getElementById("exit")
//         .setAttribute(
//           "style",
//           "position: absolute !important; right: 0px !important;  height: 94%; display: flex !important; align-items: center !important; transition: 0.3s;"
//         );
//   } else {
//     document
//       .getElementById("sistem-bar")
//       .setAttribute("style", "top: -30px; transition: 0.3s;");
//     document
//       .getElementById("header")
//       .setAttribute("style", "margin-top: 0px !important; width: 100%;");
//     document
//       .getElementById("container-logo")
//       .setAttribute(
//         "style",
//         "margin-top: auto; width: 180px; height: 100%; border-right: none !important; border-right: 1px solid #324C60 !important;"
//       );
//     if (document.getElementById("avatar-menu"))
//       document
//         .getElementById("avatar-menu")
//         .setAttribute(
//           "style",
//           "top: 14px; width: 36px; height: 36px; transition: 0.3s;"
//         );
//     if (document.getElementById("exit"))
//       document
//         .getElementById("exit")
//         .setAttribute(
//           "style",
//           "position: absolute !important; right: 55px !important;  height: 94%; display: flex !important; align-items: center !important; transition: 0.3s;"
//         );
//   }
//   prevScrollpos = currentScrollPos;
// };
</script>

<style>

html {
  /* -moz-transform: scale(0.9, 0.9); */
  /* zoom: 90%; */
  font-size: 15px !important;
}

.v-main__wrap {
  background: var(--COR_SECUNDARIA)!important;
}

.v-application  {
  /* font-size: 16px; */
  /* font-weight: 400; */
  font-family: "Open Sans", sans-serif !important;
}

.btn{
  text-transform: capitalize;
}

/* Cor das bordas dos campos  */
.v-text-field--outlined:not(.v-input--is-focused):not(.v-input--has-state)
    > .v-input__control
    > .v-input__slot
    fieldset {
      color: var(--COR_SOMBRA)!important;
      opacity: 0.5!important;
    }

/* Cor de fundo dos campos  */
.v-text-field:not(.v-input--is-focused):not(.v-input--has-state)
    > .v-input__control
    > .v-input__slot
    fieldset {
      background-color: white;
    }

/* Texto dos Inputs */
.v-input {
  font-size: 14px;
}

/* Texto de Fundo dos Texts */
.v-label {
  color: var(--COR_SOMBRA)!important;
  opacity: 0.7!important;
}

/* Texto de Fundo dos Texts Obrigatórios*/
.obrigatorio .v-label {
  color: red!important;
  opacity: 0.4!important;
}

/* Texto dos Labels de Titulos dos Texts quando possuem valores dentro */
.v-label--active {
  color: var(--COR_SUBTITULO)!important;
  font-weight: 600;
}

/* Texto dos Labels dos Radios */
.v-radio .v-label{
  color: var(--COR_SUBTITULO)!important;
  opacity: 1!important;
}

/* .v-input__slot::before {
  border: thin solid #005fcc !important;
} */

/* .v-messages .theme--light .error--text {
  border-top: thin solid #00FF00 !important;  
} */

/* .v-text-field > .v-input__control > .v-input__slot:before(:not(.v-messages) :not(.error--text)) {
  border-top: thin solid #00FF00 !important;  
  color: red;
  border-color: #F0F000;
}  */

/* .theme--light.v-text-field>.v-input__control>.v-input__slot:before {
  border-color: #F0F000;
} */

/* Texto de campos preenchidos */
/* .theme--light.v-input:not(.v-input--is-disabled) input,
.theme--light.v-input:not(.v-input--is-disabled) textarea {
    color: #505050;
} */

/* Bordas dos campos  */
/* .theme--light.v-text-field--filled > .v-input__control > .v-input__slot {
  border: 1px solid rgba(0, 0, 0, 0.1)!important;
} */

/* outlined dos campos que estão com foco */
/* .v-input--is-focused .v-input__slot fieldset  {
    border: 1px solid rgba(0, 0, 0, 0.2) !important;
} */

/* Quando está com o setfocus no campo  */
/* .v-text-field > .v-input__control > .v-input__slot:after {
  border: 1px solid rgba(255, 255, 0, 1)!important;
} */

/* .theme--light.v-text-field .v-label--active {
  color: var(--COR_SUBTITULO)!important;
  opacity: 1;
  font-size: 10px;
  font-weight: 600!important;
  margin-top: -1px;
} */

.v-messages__wrapper {
  margin-top: -1px;
}

/* Linhas das Tabelas */
.v-data-table > .v-data-table__wrapper > table > tbody > tr > th {
  color: var(--COR_SUBTITULO)!important;
  background-color: #FF0!important;
  font-weight: 600!important;
  font-weight: normal!important;
  font-size: 12px!important;
  height: 22px!important;
}

/* Titulos das Tabelas */
.v-data-table > .v-data-table__wrapper > table > thead > tr > th {
  color: var(--COR_SUBTITULO)!important;
  /* background-color: #F0F0F0!important; */
  font-weight: 600!important;
  /* font-weight: normal!important; */
  font-size: 14px!important;
  height: 26px!important;
}

.tabela {
  border-color: var(--COR_BORDA)!important;
  border-width: 1px!important;
  border-style: solid;
  /* padding-bottom: 4px; */
}

/* .tabela-cabecalho {
  color: red!important;
} */

/*  === Paginação ===  */
/* botoes do meio '...' */
.v-pagination {
  width: auto;
  margin-left: auto;
}

/* botoes laterais '<' e '>' */
.v-pagination .v-pagination__navigation {
  height: 26px !important;
  width: 26px !important;
  font-size: 11px !important;
  background-color: #91a6bb !important;  
}

/* icones dos botoes */
.v-pagination .v-pagination__navigation .v-icon {
  font-size: 11px !important;
}

/* botoes de numeros das paginas */
.v-pagination .v-pagination__item {
  height: 26px !important;
  min-width: 26px !important;
  font-size: 11px !important;
  background-color: #91a6bb !important;
}

.divisor-horizontal {
  width: 100%!important;
  position: relative;
  z-index: 0;
}

.divisor-vertical {
  height: 100%!important;
  position: relative;
  z-index: 0;  
}

.btn-expanded {
  min-width: 5px!important;
  max-width: 8px!important;
  height: 80px!important;
  position: absolute;
  top: 50%;
  right: -5px;
  z-index: 1;
}

.search_highlight {
  background-color:yellow;
}

</style>
