<template>
  <div id="LoginContainerFlutuante"  style="min-height:400px">
    
    <LoginCabecalho ref="LoginCabecalho"/>

    <form>
      <div v-if="store_Login.step === 'LoginEntrar'" style="overflow-x: hidden; min-height:400px" >
        <transition :name="store_Login.currentTransition" mode="out-in" style="overflow-x: hidden;">
          <LoginEntrar ref="LoginEntrar"/>
        </transition>
      </div>

      <div v-if="store_Login.step === 'LoginRegistrar'" style="overflow-x: hidden; min-height:400px">
        <transition :name="store_Login.currentTransition" mode="out-in" style="overflow-x: hidden;">
          <LoginRegistrar ref="LoginRegistrar"/>
        </transition>
      </div>

      <div v-if="store_Login.step === 'LoginRegistrou'" style="overflow-x: hidden; min-height:400px">
        <transition :name="store_Login.currentTransition" mode="out-in" style="overflow-x: hidden;">
          <LoginRegistrou ref="LoginRegistrou"/>
        </transition>
      </div>

      <div v-if="store_Login.step === 'LoginRegistrouSemConfirmacao'" style="overflow-x: hidden; min-height:400px">
        <transition :name="store_Login.currentTransition" mode="out-in" style="overflow-x: hidden;">
          <LoginRegistrouSemConfirmacao ref="LoginRegistrouSemConfirmacao"/>
        </transition>
      </div>

      <div v-if="store_Login.step === 'LoginReenviarSenha'" style="overflow-x: hidden; min-height:400px">
        <transition :name="store_Login.currentTransition" mode="out-in" style="overflow-x: hidden;">
          <LoginReenviarSenha ref="LoginReenviarSenha"/>
        </transition>
      </div>

    </form>

  </div>
</template>

<script>
import store_Login from "./store_Login";
import store_usuario from "../../store/store_usuario";

import LoginEntrar from "./LoginEntrar.vue";
import LoginRegistrar from "./LoginRegistrar.vue";
import LoginRegistrou from "./LoginRegistrou.vue";
import LoginRegistrouSemConfirmacao from "./LoginRegistrouSemConfirmacao.vue";
import LoginCabecalho from "./LoginCabecalho.vue";
import LoginReenviarSenha from './LoginReenviarSenha.vue';

export default {
  name: "LoginContainerFlutuante",

  components: {
    LoginCabecalho,
    LoginEntrar,
    LoginRegistrar,
    LoginRegistrou,
    LoginRegistrouSemConfirmacao,
    LoginReenviarSenha,
  },
  
  data() {
    return {
      store_Login   : store_Login,
      store_usuario : store_usuario,
    }
  },
  
  methods: {
  },

  mounted () {
    //console.log("this.$auth", this.$user);
  }


}

</script>

<style scoped lang="scss">
*,
html {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
  list-style: none;
  font-size: 16px !important;
  text-decoration: none !important;
  background: #e8e8e8;

}

/* Próximo Quadro ------------------- */
.next-leave-active {
  opacity: 0.7; }
.next-enter-active {
  transition: 0.2s; }
.next-enter {
  transform: translate(100%, 0); }
.next-leave-to {
  transform: translate(-100%, 0); }

/* Quadro Anterior ------------------ */
.prev-leave-active {
  opacity: 0.7; }
.prev-enter-active {
  transition: 0.2s; }
.prev-enter {
  transform: translate(-100%, 0); }
.prev-leave-to {
  transform: translate(100%, 0); }

</style>
