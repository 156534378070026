 <template>
  <div>

    <div id="camera">
      <div>
        <video ref="video" id="video" width="100%" height="100%" autoplay />
        <input class="d-none" ref="input_webcam" id="input_webcam" type="file" accept="image/*" capture="camera" />
      </div>
      <div>
        <v-btn
          class="mb-2"
          v-on:click="capture()"
          dark
          primary
          color="primary"
          fab><v-icon class="eye-icon">mdi-webcam</v-icon>
        </v-btn>

      </div>
      <canvas class="d-none" ref="canvas" id="canvas" width="640" height="480" />
    </div>

  </div>
</template> 
            
<script>

export default {

  props : ['dialog_webcam', 'imgcrop'],

  data() {
    return {
      video: {},
      canvas: {},
    };
  },
  
  mounted() {
    this.video = this.$refs.video;
    if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
      navigator.mediaDevices.getUserMedia({ video: true }).then((stream) => {
        video.srcObject = stream;
        video.play();
        video.onplay = function () {};
        this.video.play();
      }).catch((e) => {
        //console.log("Não encontrou cameras", e)
      })
    }
    else {
      //console.log('Não encontrou cameras')
    }
  },

  methods: {
    capture() {
      this.canvas = this.$refs.canvas;
      var context = this.canvas
        .getContext("2d")
        .drawImage(this.video, 0, 0, 640, 480);
      
      this.picture = canvas.toDataURL("image/jpg");
      this.$emit('update:dialog_webcam', false)
      this.$emit('update:imgcrop', canvas.toDataURL("image/jpg"));
    },

  eventFire(el, etype){
  if (el.fireEvent) {
    el.fireEvent('on' + etype);
  } else {
    var evObj = document.createEvent('Events');
    evObj.initEvent(etype, true, false);
    el.dispatchEvent(evObj);
  }

}
  },
};
</script> 
     

<style scoped>
#camera {
  text-align: center;
  color: #2c3e50;
}
#video {
  background-color: #000000;
}
#canvas {
  /* display: none; */
}

/* @media(min-width: 1024px) {
  input[type="file"] {
    display: none;
  }
} */

@media(max-width: 599px) {
  input[type="file"] {
    display: block;
  }
}
</style> 