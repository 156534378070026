<template>
  <div id="EditarConta">
      <v-toolbar-title v-bind:style="{ 'background-color':COR_PRINCIPAL }" class="headline lighten-2">

        <!-- Título da página -->
        <v-toolbar-title class="text-white title-page mt-3 ml-4 w-100"
          >Meus Dados
        </v-toolbar-title>
        <!-- Fim Título da página -->

        <v-toolbar-title
          class="mt-0 mb-15 ml-4 pa-0 d-flex flex-column w-100"
          dark
          elevation="0"
        >
          <div class="d-flex align-center w-100" tabindex="-1">
          </div>
        </v-toolbar-title>

        <!-- Fim Botões de ação cabeçalho -->
      </v-toolbar-title>
    
    <!-- Help -->
    <v-expansion-panels
      class="pa-4 pt-0 px-0 expansion-panel d-none"
      accordion
    >
      <v-expansion-panel>
        <v-expansion-panel-header
          class="panel-header font-weight-bold"
          disable-icon-rotate
        >
          Documentos Básicos
          <template v-slot:actions>
            <v-icon color="" class="icon-help">
              mdi-help-circle-outline
            </v-icon>
          </template>
        </v-expansion-panel-header>
        <v-expansion-panel-content class="pt-4">
          Conteúdo teste
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
    <!-- Fim Help -->

    <v-row class="mt-n11 w-100 mx-0">
      <!-- Grade ----------------------------------->
      <v-col cols="12" class="pa-0 mx-0 w-100">
        <v-container class="container-table container-rounded px-0 w-100 mx-0">

        <!-- Arquivo/Webcam Dialog ------------------------------------------ -->
        <v-dialog
          v-if="dialog_crop"
          :value="true"
          persistent
          style="width: 330"
          :max-width="330"
          :retain-focus="false"
        >
          <v-card>
            <!-- <v-card-title>Foto</v-card-title> -->
            <v-card-text class="pt-7">
              <VueCropImage
                :img="dados.foto"
                :imgsync.sync="dados.foto"
                :dialog_crop.sync="dialog_crop"
                ref="VueCropImage"
              />
            </v-card-text>
          </v-card>
        </v-dialog>
        <!-- --------------------------------------------------- -->

        <!-- Container Principal  -->
          <!-- Form Cadastro -->
          <v-row class="w-100">
            <v-col cols="12" sm="12" class="pr-5">

              <h4 class="h5 font-regular-bold">Dados Pessoais</h4>

              <v-form
                class="mt-5"
                ref="form"
                v-model="valid"
                lazy-validation
              >
                <div class="d-flex justify-center">
                  <v-avatar
                    size="100px"
                    v-ripple
                    v-if="!dados.foto"
                    class="grey lighten-2 mb-6"
                  >
                    <span v-on:click="dialog_crop = true"
                      >Clique p/ adicionar avatar
                    </span>
                  </v-avatar>
                  <v-avatar size="100px" v-ripple v-else class="mb-3">
                    <img
                      :src="dados.foto"
                      alt="avatar"
                      v-on:click="dialog_crop = true"
                    />
                  </v-avatar>

                  <!-- <image-input v-model="dados.foto">
                    <div slot="activator" v-on:click="abre()">
                      <v-avatar size="100px" v-ripple v-if="!dados.foto" class="grey lighten-2 mb-10">
                        <span>Clique p/ adicionar avatar
                          <input type="file" id="file" ref="file_new" class="d-none custom-file-input" @change="handleFile($event)">
                        </span>
                      </v-avatar>
                      <v-avatar size="100px" v-ripple v-else class="mb-3">
                        <img :src="dados.foto" alt="avatar">
                      </v-avatar>
                      <input type="file" id="file" ref="file_update" class="d-none custom-file-input" @change="handleFile($event)">
                    </div>
                  </image-input> -->
                  <!-- <v-slide-x-transition>
                    <div v-if="!dados.foto">
                      <v-btn class="primary" @click="previewFiles" >Save Avatar</v-btn>
                    </div>
                  </v-slide-x-transition> -->
                </div>

                <v-text-field
                  v-model="dados.nome"
                  class="mb-0"
                  light
                  loader-height="1"
                  background-color="#FFF"
                  label="Nome"
                  placeholder="Nome"
                  filled
                  required
                  dense
                  disabled
                  :rules="nameRules"
                ></v-text-field>

                <v-text-field
                  v-model="dados.cpf"
                  v-mask="MASK_CPFCNPJ"
                  class="mb-0"
                  light
                  loader-height="1"
                  background-color="#FFF"
                  label="CPF/CNPJ"
                  placeholder="CPF/CNPJ"
                  filled
                  :rules="cpfRules"
                  required
                  disabled
                  dense
                ></v-text-field>
                <!-- v-mask="`${ maskCPFCNPJ }`" -->

                <v-text-field
                  v-model="dados.email"
                  class="mb-0"
                  light
                  loader-height="1"
                  hint="Por exemplo: meunome@meuservidor.com.br"
                  background-color="#FFF"
                  label="Email"
                  placeholder="Digite o seu email"
                  filled
                  :rules="emailRules"
                  required
                  disabled
                  dense
                ></v-text-field>

                <v-text-field
                  v-model="dados.celular"
                  v-mask="MASK_TELEFONE"
                  class="mb-0"
                  background-color="#FFF"
                  label="Celular"
                  placeholder="(00)00000-0000"
                  filled
                  :rules="foneRules"
                  required
                  dense
                ></v-text-field>

                <v-text-field
                  label="Profissão"
                  v-model="dados.profissao"
                  filled
                  dense
                  background-color="map-deep-merge white"
                  tabindex="6"
                ></v-text-field>

              </v-form>
              <!-- Fim Form Cadastro -->
            </v-col>
            
          </v-row>

          <v-divider></v-divider>

          <v-card color="transparent" elevation="0" class="d-flex justify-center mt-3 mb-3">
              <v-btn
                v-on:click="fecha_dialogo"
                id="btn_cancelar"
                ref="btn_cancelar"
                class="mr-4"
                color="primary"
                text
              >
                Cancelar
              </v-btn>

            <v-btn
              class="btn white--text"
              color="primary accent-4"
              v-on:click="validate()"
            >
              Salvar
            </v-btn>
          </v-card>

        </v-container>
      </v-col>
      <!-- Fim Grade ----------------------------------->
    </v-row>

    <!-- <v-alert
      :value="alert"
      v-on:click="fecha_alert()"
      :color="alert_cor"
      dark
      icon="mdi-home"
      transition="scale-transition"
      elevation="1"
      :type="alert_type"
      dense
    >
      {{ alert_msg }}
    </v-alert> -->

  </div>
</template>

<script>
  import store_Usuarios from "../Usuarios/store_Usuarios";
  import store_usuario from "../../store/store_usuario";
  import store_site from "../../store/store_site";
  import {
    handleFileUpload,
    CpfCnpjIsValid,
    emailIsValid,
    telefoneIsValid,
  } from "../../services/funcoes";
  import { COR_PRINCIPAL, MASK_CPFCNPJ, MASK_TELEFONE } from "../../services/constantes";
  import { UsuarioPut, UsuarioPost } from "../../services/autenticacao";
  import { FuncoesGet } from "../../services/global";
  import VueCropImage from "../ComponentesGlobais/CropImage/VueCropImage.vue";
  const FileType = require("file-type");

  export default {
    name: "EditarConta",

    components: {
      VueCropImage,
    },

    props: ['dialog_EditarConta'],

    data() {
      return {

        store_Usuarios    : store_Usuarios,
        store_site        : store_site,
        store_usuario     : store_usuario,

        isOpen: false,

        selected  : [],
        checked   : false,

        img: null,
        imgsync: null,
        dialog_crop: false,
        dialog_webcam: false,

        MASK_CPFCNPJ      : MASK_CPFCNPJ,
        MASK_TELEFONE     : MASK_TELEFONE,
        COR_PRINCIPAL     : COR_PRINCIPAL,

        /* Campos */
        acao        : "E", // E=Edicao, I=Inclusão
        valid       : true,
        alert       : false,
        alert_msg   : "",
        alert_cor   : "red",
        alert_type  : "warning",

        stencilCoordinates: { width: 200, height: 200 },

        dados: {
          cod_usuario: null,
          nome: "",
          cpf: "",
          email: "",
          celular: "",
          nascimento_dt: null,
          profissao: null,
          estado_civil: null,
          situacao: null,
          foto: null,
          usuario_funcao: [],
        },

        /* Formulário Reprovação */
        form: false,
        nameRules: [
          (value) => !!value || "O nome é obrigatório",
          (value) =>
            (value && value.length >= 3) ||
            "O nome deve ter no mínimo 3 caracteres",
          (value) =>
            (value && value.length <= 60) ||
            "O nome deve ter menos do que 60 caracteres",
        ],
        emailRules: [
          (value) => !!value || "O e-mail é obrigatório",
          (value) => (value && emailIsValid(value)) || "O e-mail não é válido",
        ],
        foneRules: [
          (value) => !!value || "O celular é obrigatório",
          (value) =>
            (value && telefoneIsValid(value)) || "O celular não é válido",
        ],
        cpfRules: [
          (value) => !!value || "O CPF é obrigatório",
          (value) => (value && CpfCnpjIsValid(value)) || "O CPF não é válido",
        ],
      };
    },

    watch: {
      // dialog_crop() {
      //   console.log('MUDOU');
      // },
      // imgSrc() {
      //   console.log('MUDOU ImgScr');
      // }
    },

    created() {
      //console.log("created");
    },

    async mounted() {
      var lo_params = { cod_usuario: this.store_usuario.user.cod_usuario };
      //console.log(lo_params)

      // Edição do cadastro -----------------------------------------
      if (lo_params.cod_usuario) {
        var lo_Res = await this.store_Usuarios.UsuariosGet(lo_params);
        if (lo_Res)
          this.dados = { ...lo_Res };
        this.acao = "E";
        //console.log(this.dados)
      }
    },

    methods: {

      fecha_dialogo() {
        this.$emit('update:dialog_EditarConta', false)
      },

      startCameraStream() {
        const constraints = (window.constraints = {
          audio: false,
          video: true,
        });
        navigator.mediaDevices
          .getUserMedia(constraints)
          .then((stream) => {
            this.$refs.camera.srcObject = stream;
          })
          .catch((error) => {
            console.log(
              "Browser doesn't support or there is some errors." + error
            );
          });
      },

      async getDevices() {
        if (!navigator.mediaDevices || !navigator.mediaDevices.enumerateDevices) {
          //console.log("NAO")
          return false;
        }
        try {
          let allDevices = await navigator.mediaDevices.enumerateDevices();
          //console.log("SIM", allDevices)
          for (let mediaDevice of allDevices) {
            if (mediaDevice.kind === "videoinput") {
              let option = {};
              option.text = mediaDevice.label;
              option.value = mediaDevice.deviceId;
              this.options.push(option);
              this.devices.push(mediaDevice);
            }
          }
          return true;
        } catch (err) {
          throw err;
        }
      },

      // setImage(e) {
      //   const file = e.target.files[0];

      //   if (file.type.indexOf('image/') === -1) {
      //     alert('Please select an image file');
      //     return;
      //   }

      //   if (typeof FileReader === 'function') {
      //     const reader = new FileReader();

      //     reader.onload = (event) => {
      //       this.imgSrc = event.target.result;
      //       // rebuild cropperjs with the updated source
      //       this.$refs.cropper.replace(event.target.result);
      //     };

      //     reader.readAsDataURL(file);
      //   } else {
      //     alert('Sorry, FileReader API not supported');
      //   }
      // },

      // showFileChooser() {
      //   this.$refs.input.click();
      // },

      // --------------------------------------------------------------------------
      // --------------------------------------------------------------------------
      // --------------------------------------------------------------------------
      abre() {
        if (!this.dados.foto) {
          this.$refs.file_new.click();
        } else {
          this.$refs.file_update.click();
        }
      },

      fecha_alert() {
        this.alert = false;
      },

      /* Método que recebe arquivo de imagem */
      handleFile({ target }) {
        this.dialog_crop = true;

        // let reader = new FileReader();
        // if (target.files && target.files.length > 0) {
        //   reader.readAsDataURL(target.files[0]);
        //   reader.onload = async (file) => {
        //     this.dados.foto = file.target.result
        //     this.dialog_crop = true;
        //     //console.log("this.$refs.vue_crop_image", this.$refs);
        //   }
        // }
      },

      async validate() {
        const lb_valido = this.$refs.form.validate();
        if (lb_valido) {
          var lo_JSON = { ...this.dados };

          // Editando usuário ----------------
          var ls_form;
          ls_form = await UsuarioPut(lo_JSON);

          if (ls_form.result.message == "success") {
            //console.log(ls_form.result.result)
            this.store_site.alert_cor    = "green";
            this.store_site.alert_type   = "success";
            this.store_site.alert_msg    = ls_form.result.result;
            this.store_site.alert        = true;
            this.fecha_dialogo();
          } else {
            this.store_site.alert_cor    = "red";
            this.store_site.alert_type   = "warning";
            this.store_site.alert_msg    = ls_form.errors;
            this.store_site.alert        = true;
          }
        }
      },
      reset() {
        this.$refs.form.reset();
      },
      resetValidation() {
        this.$refs.form.resetValidation();
      },
    },
  };
</script>

<style scoped>

  .main-title {
    background: #0548a3;
  }

  .title-page {
    font-family: "Montserrat", sans-serif;
    letter-spacing: 0px;
  }

  .text-white {
    color: #FFFFFF;
    letter-spacing: 0px;
  }

  .container-principal {
    max-width: calc(100% - 32px) !important;
    margin-bottom: 20px !important;
  }

  .container-rounded {
    max-width: calc(100% - 32px) !important;
    background: #F2F6F7 !important;
    border-radius: 12px !important;
    margin: auto !important;
    margin-bottom: 20px !important;
    padding: 20px 13px 13px 13px !important;
  }

  /* ======================== AVATAR UPLOAD ===================== */
  .mx-auto {
    margin: auto;
  }

   .input-file {
    width: 110px !important;
    height: 35px !important;
    border-radius: 50%;
    position: absolute !important;
    opacity: 0 !important;
    cursor: pointer !important;
    outline: none !important;
    z-index: 2;
  }

  .avatar-container__file {
    width: 100%;
    height: 100%;
    margin: 0px;
    border-radius: 50%;
    position: absolute;
    opacity: 0;
    cursor: pointer;
    outline: none;
  }

  /* ======================== PRIVILEGES ===================== */
  .h5 {
    font-family: "Montserrat", sans-serif;
    color: rgba(0, 0, 0, 0.87);
  }

  .h5::after {
    display: block;
    width: 50px;
    height: 3px;
    margin-top: 0.2rem;
    content: "";
    background: #274abb;
  }

  .container-privileges {
    margin-bottom: 24px;
    padding-left: 12px;
    border-radius: 8px;
    background: #fff;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px,
      rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;
  }

  .btn {
    width: 120px;
  }

  .v-btn {
    display: inline-block;
  }

  /* CELULAR */
  @media (max-width: 599px) {

    .container,
    .container-principal {
      margin: 0px !important;
      padding: 0px 8px !important;
    }

    .container-rounded {
      background: #F2F6F7 !important;
      border-radius: 12px !important;
      max-width: calc(100%) !important;
      margin: auto !important;
      padding: 20px 13px 13px 13px !important;
    }

    .title-page {
      margin-left: 16px !important;
    }

    .theme--light.v-data-table {
      border-radius: 12px 12px 0px 0px !important;
    }

    .avatar {
      margin: 0px 0px 0px 4px !important;
    }
    
    .status-mobile {
      display: block;
    }

    .btn-icon {
      margin-right: 0px !important;
    }
    .search-input {
      margin: 0.8rem 0 1.2rem 0;
      min-width: auto;
    }
    .search-input-longo {
      margin: 0.8rem 10% 1.2rem 0;
      height: 35px !important;
      /* min-width: calc(100% - 15px); */
    }
    .search-input--outlined {
      background-color: #FF0000;
    }
  }

</style>