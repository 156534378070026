<template>
  <v-container id="LoginEntrar" style="min-height:600px; background-color:transparent" >

      <!-- Container Form Login -->
      <v-container class="modal-wrapper w-100 pa-0 justify-center" style="background-color:transparent">

        <!-- Form Login-->
        <v-form class="pa-4"
                ref="form"
                v-model="valid"
                lazy-validation
        >
          <v-layout justify-center align-center>
            <v-img class="logo mb-4" src="../../assets/younect_vertical.png" />
          </v-layout>
            
          <!-- <h2 align="center" style="color:#0548a3">Faça seu login ou cadastre-se</h2><br> -->
          <h2 style="color:#F7F7F7">Faça seu login ou cadastre-se</h2>
          <span class="register-create-login">{{store_usuario.user.cod_empresa }} / {{store_site.cod_empresa }}</span>

          <!-- <v-alert 
            :value="alert"
            v-on:click="fecha_alert()"
            color="red"
            dark
            icon="mdi-home"
            transition="scale-transition"
            elevation="1"
            type="warning"
            dense
          >
            {{alert_msg}}
          </v-alert> -->

          <v-text-field
            class="mb-0"
            v-model="store_usuario.user.email"
            light
            loader-height="1"
            hint="Por exemplo: meunome@meuservidor.com.br"
            background-color="white"
            label="Email ou CPF/CNPJ"
            placeholder="Digite o email ou CPF/CNPJ"
            filled
            
            required
            dense
          ></v-text-field>

          <v-text-field
            class="mb-0"
            v-model="senha"
            background-color="white"
            label="Senha"
            placeholder="Digite sua senha (obrigatório letras e números) "
            filled
            type="password"
            :rules="passwordRules"
            required
            dense
          ></v-text-field>

        </v-form>
        <!-- End Form Login -->
      </v-container>
      <!-- End Container Form Login -->

    <!-- Modal Footer -->
    <div class="button-container w-100 pa-2 absolute d-flex justify-content-between flex-column align-center"
         style="height:120px; background-color:transparent">
      <v-btn
        class="mt-n16 mb-9 text-none"
        :disabled="!valid"
        @click="validate"
        color="success"
        :loading="loading">
        <v-icon left> mdi-login </v-icon>
        Entrar
      </v-btn>

      <!-- <p class="body-2 register-login"
          style="color:#F7F7F7">
          Não possui uma conta?
        <span v-on:click="store_Login.currentTransition='next';store_Login.step='LoginRegistrar'"
              style="color:#F7F7F7"
              class="register-create-login body-2">
          Cadastre-se
        </span>
      </p> -->

      <p class="body-2 register-login"
         style="color:#F7F7F7">
         Esqueceu a senha?
        <span v-on:click="reenviarSenha"
              style="color:#F7F7F7"
              class="register-create-login body-2">
          Reenviar senha
        </span>
      </p>
      <!-- <v-btn class="mb-4 text-none" v-on:click="store_Login.currentTransition='next';store_Login.step='LoginRegistrar'" color="primary"  outlined>
        <v-icon left> mdi-wallet </v-icon>
        Registrar
      </v-btn> -->
      
      <!-- <v-btn class="text-none" @click="reenviarSenha" color="primary" outlined>
        <v-icon left> mdi-wallet </v-icon>
        Reenviar Senha
      </v-btn> -->


    </div>
    <!-- End Modal Footer -->

  </v-container>

</template>

<script type="text/javascript">
import store_Login from "./store_Login";
import store_site from "../../store/store_site";
import store_usuario from "../../store/store_usuario";
////++++import store_ModalNegociacao from "../../components/ModalNegociacao/store_ModalNegociacao";
import { COR_PRINCIPAL, COR_SECUNDARIA } from "../../services/constantes";
import { GS_APLICACAO_INICIAL } from "../../services/global";

import { ReenviarCodigoSenhaPorEmail } from "../../services/autenticacao";
const moment = require('moment');

import { logIn } from "../../services/autenticacao";

export default {
  components: {
  },
  data() {
    return {
      store_Login           : store_Login,
      store_site            : store_site,
      store_usuario         : store_usuario,
      ////++++store_ModalNegociacao : store_ModalNegociacao,

      COR_PRINCIPAL         : COR_PRINCIPAL,
      COR_SECUNDARIA        : COR_SECUNDARIA,

      loading               : false,
      valid                 : false,
      //alert                 : false,
      //alert_msg             : "",
      senha                 : "",
      nameRules : [
        (value) => !!value || "O nome é obrigatório",
        (value) =>
          (value && value.length >= 3) ||
          "O nome deve ter no mínimo 3 caracteres",
        (value) =>
          (value && value.length <= 60) ||
          "O nome deve ter menos do que 60 caracteres",
      ],
      emailRules: [
        (value) => !!value || "O e-mail é obrigatório",
        (value) => /.+@.+\..+/.test(value) || "O e-mail não é válido",
      ],
      passwordRules: [
        (value) => !!value || "A senha é obrigatória",
        (value) =>
          (value && value.length >= 3) ||
          "A senha deve ter no mínimo 3 caracteres",
        (value) =>
          (value && value.length <= 15) ||
          "O nome deve ter menos do que 15 caracteres",
      ],
    };
  },

  watch: {
    "store_usuario.user.email": function (val) {
      if (this.store_usuario.user.email)
        this.store_usuario.user.email = val.toLowerCase().trim();
    },
  },

  computed: {
    isMobile() {
      return this.$vuetify.breakpoint.name === "xs";
    },
  },

  methods: {

    // fecha_alert() {
    //   this.alert = false;
    // },

    // Valida os dados e se estiverem corretos, efetua o login
    async validate () {
      const lb_valido = this.$refs.form.validate();
      if (lb_valido) {
        this.loading = true;
        let ls_login = null;
        try {
          ls_login = await logIn ({ email       : this.store_usuario.user.email.toLowerCase().trim(),
                                    senha       : this.senha,
                                    // cod_empresa : this.store_site.cod_empresa
                                 });

          if (ls_login == 'success') {
            this.loading = false;
            this.store_site.alert = false;
            this.senha = "";
            var ls_Found = this.store_usuario.user.pessoa_funcao.find(o => (o.cod_funcao == 2 || o.cod_funcao == 3 || o.cod_funcao == 4 || o.cod_funcao == 5))
            //var ls_Found = this.store_usuario.user.pessoa_funcao.find(o => (o.cod_funcao == 0)) 
            if (ls_Found)
              this.store_usuario.tipo_cliente = "USUARIO"
            else
              this.store_usuario.tipo_cliente = "CLIENTE FINAL"
            ////++++if (this.store_usuario.tipo_cliente == "CLIENTE FINAL")
            ////++++  this.store_ModalNegociacao.step = 'ModalSelecaoPagamento'
            ////++++else {  
            ////++++  if (this.store_ModalNegociacao.acao == "PROPOSTA")
            ////++++    this.store_ModalNegociacao.step = 'ModalSelecaoPagamento'
            ////++++  else if (this.store_ModalNegociacao.acao == "RESERVA")
            ////++++    this.store_ModalNegociacao.step = 'ModalReservaConfirmacao'
            ////++++  else if (this.store_ModalNegociacao.acao == "ORCAMENTO")
            ////++++    this.store_ModalNegociacao.step = 'ModalSelecaoPagamento'
            ////++++}
            //this.store_Negociacoes.filtra_negociacoes_abertas();            
            this.store_Login.dialog_flutuante = false;
            if (this.store_usuario.tipo_cliente == "CLIENTE FINAL")
              this.$router.push('painelcliente');
            else
              this.$router.push(GS_APLICACAO_INICIAL);

            //this.$router.push('CnabRetorno');
          }
          else {
            this.store_site.alert_timeout = 10000;
            this.store_site.alert_cor     = 'red';
            this.store_site.alert_msg     = ls_login;
            this.store_site.alert         = true;
            this.loading                  = false;
          }
        }
        catch(err) {
          this.store_site.alert_timeout = 10000;
          this.store_site.alert_cor     = 'red';
          this.store_site.alert_msg     = 'Erro de conexão com servidor ou falha na rede. Tente novamente mais tarde! ' + err;
          this.store_site.alert         = true;
          this.loading                  = false;
        }
      }
    },

    async reenviarSenha() {
      if (store_usuario.user.email) {

        // Enviar codigo para nova senha para o email solicitado (usuário não está logado)
        const ls_resp = await ReenviarCodigoSenhaPorEmail({ email       : store_usuario.user.email,
                                                            cod_empresa : this.store_site.cod_empresa
                                                          });
        if (ls_resp.message == 'success') {
          store_Login.currentTransition = 'next';
          store_Login.step              = 'LoginReenviarSenha';
        }
        else {
          setTimeout(() => {
            this.alert = false;
          }, 3000);
          this.alert      = true;
          this.alert_msg  = ls_resp.errors;
        }

      }
        else {
          setTimeout(() => {
            this.alert = false;
          }, 3000);
          this.alert      = true;
          this.alert_msg  = 'Preencha o email';
        }
    },

  }

};

</script>

<style scoped lang="scss">
  *,
  html {
    margin: 0px;
    padding: 0px;
    box-sizing: border-box;
    list-style: none;
    font-size: 16px !important;
    text-decoration: none !important;
  }

  .modal-wrapper {
    overflow-y: auto;
    height: 416px;
  }

  .button-container {
    background: #e8e8e8;
    z-index: 999;
  }

  .btn-login {
    width: 150px;
  }

  .input-text {
    ::v-deep {
      .v-text-field {
        color: chocolate;
      }
      .v-text-field__details {
        margin-top: "2px" !important;
      }
    }
  }

  .error--text {
    font-size: 12px !important;
  }

  .v-counter {
    font-size: 12px !important;
  }

  .v-messages__message {
    padding-top: {
      font-size: 12px !important;
    }
  }

  $text-field-details: 12px !important;

  .v-input__slot {
    margin-bottom: 0px !important;
    background: aqua !important;
  }

  .v-text-field__details {
    font-family: "Montserrat", sans-serif !important;
    font-size: 12px !important;
  }

  .button-container {
    background: #e8e8e8;
    z-index: 999;
  }

  .button-container button {
    width: 220px!important;
  }

.register-login {
  color: #37383c;
}

.register-create-login {
  color: var(--COR_PRINCIPAL);
  text-decoration: underline!important;
}

.register-create-login:hover {
  cursor: pointer;
  text-decoration: underline!important;
  transition: 0.3s;
}

.logo-container {
	width: 100%;
	max-height: 120px;
  // background-color: transparent!important;

}

.logo {
	max-width: 120px;
	max-height: 120px;
	height: auto;
	display: block;
  background-color: transparent!important;
}


</style>
