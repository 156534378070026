<template>
  <div id="LoginReenviarSenha" style="min-height:450px" v-bind:style="{ 'background-color':COR_SECUNDARIA }">

    <v-layout row wrap class="modal-wrapper-owner align-center">

    <!-- Modal Content -->
    <v-layout row wrap class="modal-wrapper w-100 ml-0 pt-4">
      <!-- Container Form Contato -->
      <v-container class=" w-100 pa-0" style="min-height:370px" v-bind:style="{ 'background-color':COR_SECUNDARIA }">

        <h2 align="center"  style="color:#0548a3">Preencha as novas senhas e </h2>
        <h2 align="center"  style="color:#0548a3">acesse o email: <span style="color:#303030">{{store_usuario.user.email}}</span></h2>
        <h2 align="center"  style="color:#0548a3" class="mb-3">para confirmar o código de alteração !</h2>

        <v-alert 
          :value="alert"
          v-on:click="fecha_alert()"
          color="red"
          dark
          icon="mdi-home"
          transition="scale-transition"
          elevation="1"
          type="warning"
          dense
        >
          {{alert_msg}}
        </v-alert>

        <v-form class="pa-4 pt-0"
                ref="form"
                v-model="valid"
                lazy-validation
        >
          <v-text-field
            label="Nova senha *"
            id="senha1"
            name="senha1"
            v-model="senha1"
            filled
            dense
            required
            min=6
            max=12
            type="password"
            background-color="map-deep-merge white"
            :rules="senhaRules"
          ></v-text-field>

          <v-text-field
            label="Confirme nova senha *"
            id="senha2"
            name="senha2"
            v-model="senha2"
            filled
            dense
            required
            min=6
            max=12
            type="password"
            background-color="map-deep-merge white"
            :rules="senhaRules"
          ></v-text-field>

          <v-text-field
            ref="codigo"
            label="Código de confirmação enviado por email *"
            v-model="codigo"
            filled
            dense
            background-color="map-deep-merge white"
            required
            min=6
            max=6
            type="number"            
            :rules="codigoRules"
            oninput="if (this.value.length > Number(this.max)) this.value = this.value.substr(0,6);"
          ></v-text-field>


        </v-form>

      </v-container>
      <!-- End Container Form Login -->
    <!-- End Modal Content -->

    <!-- Modal Footer -->
   <v-container class="d-flex justify-center button-container w-100 mb-4" v-bind:style="{ 'background-color':COR_SECUNDARIA }">

      <v-btn class="text-none mr-4" color="primary" v-on:click="store_login.currentTransition='prev';store_login.step='LoginEntrar'" outlined>
        <v-icon left>
          mdi-close
        </v-icon>
        Anterior
      </v-btn>
      <v-btn class="text-none" ref="btn_confirmar" @click="validate" color="primary" >
        <v-icon left>
          mdi-content-save
        </v-icon>
        Confirmar
      </v-btn>

    </v-container>
    <!-- End Modal Footer -->

    <!-- Modal Footer -->
    <!-- <div class="button-container w-100 pa-2 absolute d-flex justify-content-between flex-column align-center" style="height:60px">
      <v-btn class="mr-4" color="primary" v-on:click="store_login.currentTransition='prev';store_login.step='LoginEntrar'" outlined>
        <v-icon left>
          mdi-close
        </v-icon>
        Anterior
      </v-btn>
      <v-btn ref="btn_confirmar" @click="validate" color="primary" :disabled="!valid" >
        <v-icon left>
          mdi-content-save
        </v-icon>
        Confirmar
      </v-btn>
    </div> -->

    <!-- End Modal Footer -->
    </v-layout>
  </v-layout>
  </div>
</template>

<script type="text/javascript">

import store_site from "../../store/store_site";
import store_login from "./store_Login";
import store_usuario from "../../store/store_usuario";
////++++import store_ModalNegociacao from "../../components/ModalNegociacao/store_ModalNegociacao";
import { COR_PRINCIPAL, COR_SECUNDARIA } from "../../services/constantes";
import { GS_APLICACAO_INICIAL } from "../../services/global";
import { logIn, UsuarioSenhaPost } from "../../services/autenticacao";

export default {
  name: "LoginReenviarSenha",

  data() {
    return {
      store_site            : store_site,
      store_login           : store_login,
      store_usuario         : store_usuario,
      ////++++store_ModalNegociacao : store_ModalNegociacao,

      valid                 : true,
      alert                 : false,
      alert_msg             : "",

      codigo                : null,
      senha1                : null,
      senha2                : null,

      COR_PRINCIPAL         : COR_PRINCIPAL,
      COR_SECUNDARIA        : COR_SECUNDARIA,

      codigoRules : [
        (value) => !!value || "O código é obrigatório",
        (value) =>
          (value && value.length == 6) ||
          "O código deve ter 6 caracteres",
      ],
      senhaRules : [
        (value) => !!value || "Senha é obrigatória",
        (value) =>
          (value && value.length >= 6) ||
          "Senha deve ter no mínimo 6 caracteres",
        (value) =>
          (value && value.length <= 12) ||
          "Senha deve ter menos do que 12 caracteres",
      ],
    };

  },

  methods: {
    fecha_alert() {
      this.alert = false;
    },

    async validate () {
      if (this.senha1 != this.senha2) {
        this.store_site.alert_cor    = "red";
        this.store_site.alert_msg    = "Senhas não coincidem !";
        this.store_site.alert        = true;
      }
      else {
        const lb_valido = this.$refs.form.validate();
        if (lb_valido) {

          // Gravando nova senha enviando código gerado
          const ls_form = await UsuarioSenhaPost({codigo       : this.codigo,
                                                   senha       : this.senha1,
                                                   cod_empresa : this.store_site.cod_empresa
                                                  });
          if (ls_form.result.message == 'success') {
            const ls_login = await logIn({email       : ls_form.result.result.email,
                                          senha       : this.senha1,
                                          cod_empresa : this.store_site.cod_empresa
                                        });
            if (ls_login == 'success') {
              this.store_site.alert_cor    = "green";
              this.store_site.alert_msg    = "Senha cadastrada com sucesso !";
              this.store_site.alert        = true;
              this.senha = "";
              ////++++this.store_ModalNegociacao.currentTransition = 'next';
              this.store_login.step = 'LoginEntrar';
              this.store_login.dialog_flutuante=false;
              this.$router.push(GS_APLICACAO_INICIAL);
            }
            else {
              this.store_site.alert_cor    = "red";
              this.store_site.alert_msg    = ls_login;
              this.store_site.alert        = true;
            }

          }
          else {
            setTimeout(() => {
              this.alert = false;
            }, 3000);
            this.alert = true;
            this.alert_msg = ls_form.errors;
          }
        }
      }
    },
    reset () {
      this.$refs.form.reset()
    },
    resetValidation () {
      this.$refs.form.resetValidation()
    },
  }

};
</script>

<style scoped lang="scss">
*,
html {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
  list-style: none;
  font-size: 16px !important;
  text-decoration: none !important;
}

.modal-wrapper {
  overflow-y: auto;
  height: 350px;
}

.button-container {
  background: #e8e8e8;
  z-index: 999;
}

.btn-login {
  width: 150px;
}

.input-text {
  ::v-deep {
    .v-text-field {
      color: chocolate;
    }
    .v-text-field__details {
      margin-top: "2px" !important;
    }
  }
}

.error--text {
  font-size: 12px !important;
}

.v-counter {
  font-size: 12px !important;
}

.v-messages__message {
  padding-top: {
    font-size: 12px !important;
  }
}

$text-field-details: 12px !important;

.v-input__slot {
  margin-bottom: 0px !important;
  background: aqua !important;
}

.v-text-field__details {
  font-family: "Montserrat", sans-serif !important;
  font-size: 12px !important;
}

</style>


<style scoped>
*,
html {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
  list-style: none;
  font-size: 16px !important;
  text-decoration: none !important;
}

.modal-wrapper {
  overflow-y: auto;
  height: 460px;
}

.modal-wrapper-owner {
  overflow-y: auto;
  height: calc(100vh);
}

.button-container {
  background: #e8e8e8;
  z-index: 999;
}

.v-btn {
  width: 150px;
}
</style>
