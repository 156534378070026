<template>
  <div id="LoginRegistrar" style="min-height:450px" v-bind:style="{ 'background-color':COR_SECUNDARIA }">

    <v-container class="modal-wrapper-owner pa-4" v-bind:style="{ 'background-color':COR_SECUNDARIA }">

      <!-- Modal Footer -->
      <v-container class="modal-wrapper justify-center" v-bind:style="{ 'background-color':COR_SECUNDARIA }">

        <!-- Form Contact-->
        <h2 align="center" class="mb-4" style="color:#0548a3">Preencha os dados abaixo pra se cadastrar</h2><br>

        <v-alert 
          :value="alert"
          v-on:click="fecha_alert()"
          color="red"
          dark
          icon="mdi-home"
          transition="scale-transition"
          elevation="1"
          type="warning"
          dense
        >
          {{alert_msg}}
        </v-alert>

        <v-form ref="form"
                v-model="valid"
                lazy-validation
        >
          <v-text-field
            ref="nome"
            label="Nome *"
            v-model="nome"
            filled
            dense
            background-color="map-deep-merge white"
            required
            :rules="nameRules"
            tabindex="1"
          ></v-text-field>

            <!-- <v-text-field
              v-model="cpf"
              v-mask="MASK_CPFCNPJ"
              class="mb-0"
              light
              loader-height="1"
              background-color="#FFF"
              label="CPF/CNPJ"
              placeholder="CPF/CNPJ"
              filled
              :rules="cpfRules"
              required
              dense
            ></v-text-field> -->

          <v-text-field
            v-model="cpf"
            v-mask="MASK_CPFCNPJ"
            label="CPF *"
            filled
            dense
            required
            background-color="map-deep-merge white"
            :rules="cpfRules"
            tabindex="2"
          ></v-text-field>

          <v-text-field
            label="Email *"
            v-model="email"
            filled
            dense
            required
            background-color="map-deep-merge white"
            :rules="emailRules"
            tabindex="3"
          ></v-text-field>

          <v-text-field
            label="Celular *"
            v-model="celular"
            filled
            dense
            required
            background-color="map-deep-merge white"
            v-mask="MASK_TELEFONE"
            :rules="foneRules"
            tabindex="4"
          ></v-text-field>

          <v-text-field
            label="Data de Nascimento"
            v-model="nascimento_dt"
            filled
            dense
            background-color="map-deep-merge white"
            type="date"
            tabindex="5"
          ></v-text-field>

          <v-text-field
            label="Profissão"
            v-model="profissao"
            filled
            dense
            background-color="map-deep-merge white"
            tabindex="6"
          ></v-text-field>

          <v-select
            label="Estado Civil"
            v-model="estado_civil"
            class="mb-10"
            filled
            dense
            background-color="map-deep-merge white"
            tabindex="7"
            :items="[
              'Solteiro (a)',
              'Casado (a)',
              'Divorciado (a)',
              'Viuvo (a)',
              'Separado (a) Judicialmente',
            ]"
          ></v-select>

          <v-text-field
            label="Nova Senha *"
            id="novasenha1"
            name="novasenha1"
            v-model="novasenha1"
            filled
            dense
            required
            min=6
            max=12
            type="password"
            background-color="map-deep-merge white"
            :rules="senhaRules"
            tabindex="8"
            autocomplete="new-password"
          ></v-text-field>

          <v-text-field
            label="Confirme Nova Senha *"
            id="novasenha2"
            name="novasenha2"
            v-model="novasenha2"
            filled
            dense
            required
            min=6
            max=12
            type="password"
            background-color="map-deep-merge white"
            :rules="senhaRules"
            tabindex="9"
            autocomplete="new-password"
          ></v-text-field>

        </v-form>
        <!-- End Form Contact -->

      </v-container>

      <!-- Modal Footer -->
      <v-container class="d-flex justify-center button-container w-100 mb-4" v-bind:style="{ 'background-color':COR_SECUNDARIA }">

        <v-btn class="text-none mr-4" color="primary" v-on:click="store_login.currentTransition='prev';store_login.step='LoginEntrar'" outlined>
          <v-icon left>
            mdi-close
          </v-icon>
          Anterior
        </v-btn>
        <v-btn class="text-none" ref="btn_confirmar" @click="validate" color="primary" >
          <v-icon left>
            mdi-content-save
          </v-icon>
          Confirmar
        </v-btn>

      </v-container>

    <!-- End Modal Footer -->
    </v-container>

  </div>
</template>

<script type="text/javascript">
import store_site from "../../store/store_site";
import store_login from "./store_Login";
import store_usuario from "../../store/store_usuario";
import { COR_PRINCIPAL, COR_SECUNDARIA } from "../../services/constantes";

import { maskCPFCNPJ, maskTELEFONE, CpfCnpjIsValid, emailIsValid, telefoneIsValid } from "../../services/funcoes";
import { MASK_CPFCNPJ, MASK_TELEFONE } from "../../services/constantes";

import { UsuarioPost, UsuarioSitePost } from "../../services/autenticacao";

export default {
  name: "LoginRegistrar",

  data() {
    return {
      store_site            : store_site,
      store_login           : store_login,
      store_usuario         : store_usuario,

      COR_PRINCIPAL         : COR_PRINCIPAL,
      COR_SECUNDARIA        : COR_SECUNDARIA,

      valid                 : true,
      alert                 : false,
      alert_msg             : "",

      nome                  : null,
      cpf                   : null,
      //maskCPFCNPJ           : "",
      email                 : null,
      celular               : "",
      //maskTELEFONE          : null,
      nascimento_dt         : null,
      profissao             : null,
      estado_civil          : null,
      novasenha1            : null,
      novasenha2            : null,

      MASK_CPFCNPJ          : MASK_CPFCNPJ,
      MASK_TELEFONE         : MASK_TELEFONE,

      form: false,
      nameRules : [
        (value) => !!value || "O nome é obrigatório",
        (value) =>
          (value && value.length >= 3) ||
          "O nome deve ter no mínimo 3 caracteres",
        (value) =>
          (value && value.length <= 60) ||
          "O nome deve ter menos do que 60 caracteres",
      ],
      emailRules: [
        (value) => !!value || "O e-mail é obrigatório",
        (value) => (value && emailIsValid(value)) || "O e-mail não é válido",
      ],
      foneRules: [
        (value) => !!value || "O celular é obrigatório",
        (value) => (value && telefoneIsValid(value)) || "O celular não é válido",
      ],
      cpfRules: [
        (value) => !!value || "O CPF é obrigatório",
        (value) => (value && CpfCnpjIsValid(value)) || "O CPF não é válido",
      ],
      senhaRules : [
        (value) => !!value || "Senha é obrigatória",
        (value) =>
          (value && value.length >= 6) ||
          "Senha deve ter no mínimo 6 caracteres",
        (value) =>
          (value && value.length <= 12) ||
          "Senha deve ter menos do que 12 caracteres",
      ],

    };
  },

  watch: {
  //   cpf(value) {
  //       this.MASK_CPFCNPJ = maskCPFCNPJ(value);
  //   },
  //   celular(value) {
  //       this.MASK_TELEFONE = maskTELEFONE(value);
  //   }
    email: function (val) {
      this.email = val.toLowerCase();
    },
  },

  mounted() {
    this.$refs.nome.focus();
    this.$refs.nome.cachedItems = [];
  },

  methods: {
    fecha_alert() {
      this.alert = false;
    },

    async validate () {
      if (this.novasenha1 != this.novasenha2) {
          setTimeout(() => {
            this.alert = false;
          }, 3000);
          this.alert = true;
          this.alert_msg = "Senhas não coincidem !";
      }
      else {
        const lb_valido = this.$refs.form.validate();
        if (lb_valido) {
          var ls_form;
          // if (!store_usuario.autenticado)
          //    ls_form = await UsuarioSitePost({  nome            : this.nome,
          //                                       cpf             : this.cpf,
          //                                       email           : this.email,
          //                                       celular         : this.celular,
          //                                       nascimento_dt   : this.nascimento_dt,
          //                                       profissao       : this.profissao,
          //                                       estado_civil    : this.estado_civil,
          //                                       senha           : this.novasenha1,
          //                                       cod_empresa     : this.store_site.cod_empresa,
          //                                       funcoes         : [ { cod_funcao: 1 }]    // 1=Cliente
          //                                     });
          // else
            ls_form = await UsuarioPost(  { nome            : this.nome,
                                            cpf             : this.cpf,
                                            email           : this.email,
                                            celular         : this.celular,
                                            nascimento_dt   : this.nascimento_dt,
                                            profissao       : this.profissao,
                                            estado_civil    : this.estado_civil,
                                            senha           : this.novasenha1,
                                            cod_empresa     : this.store_site.cod_empresa,
                                            funcoes         : null
                                          });
          //console.log("ls_form", ls_form)
          if (ls_form.result.message == 'success') {
            //console.log("SALVOU")
            this.store_site.alert = false
            this.novasenha1 = "";
            this.novasenha2 = "";
            //this.store_login.step = "LoginRegistrouSemConfirmacao";
            //this.store_login.dialog_flutuante=false
          }
          else {
            //console.log("NAO SALVOU")
            this.store_site.alert = true;
            this.store_site.alert_cor = '#FF0000'
            this.store_site.alert_msg = ls_form.errors;
          }
        }
      }
    },

    reset () {
      this.$refs.form.reset()
    },

    resetValidation () {
      this.$refs.form.resetValidation()
    },
  }

};
</script>

<style scoped>
*,
html {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
  list-style: none;
  font-size: 16px !important;
  text-decoration: none !important;
}

.modal-wrapper {
  overflow-y: auto;
  /* height: 820px; */
  /* background-color:red !important; */
}

.modal-wrapper-owner {
  overflow-y: auto;
  height: calc(100vh);
}

.button-container {
  background: #e8e8e8;
  z-index: 999;
  align-self: center!important;
}

.v-btn {
  width: 150px;
}

.lowecase input{
  text-transform: lowercase
}

.v-text-field {
  width: 100%;
}

</style>
