<template>
  <div id="LoginCabecalho" >
    <!-- Modal Header -->
    <div class="modal-n-header d-flex flex-column pl-3 pt-2 pb-2" style="height: 100%">
      <table style="border-collapse: collapse; width: 100%;" border="0">
        <tbody>
          <tr style="width:500px; valign:top">

            <td style="width:500px">
              <table style="border-collapse: collapse; width: 100%" border="0">
                <tbody>
                  <tr style="width:500px">
                    <td style="text-align:center">
                      <div class="modal-title font-light-bold modal-font ml-1"></div>
                    </td>
                    <td style="width:20px">
                      <v-btn icon dark v-on:click="FechaDialogo()" class="mt-n3">
                        <v-icon large class="title">mdi-close</v-icon>
                      </v-btn>
                    </td>
                  </tr>
                </tbody>
              </table>

              <table style="border-collapse: collapse; width: 100%; " border="0">
                <tbody>
                  <tr style="width:500px">
                    <td style="width:50%; text-align:center ">
                      <!-- <hr style="margin-top:0px;margin-bottom:8px;margin-left:15px;color:#A0A0FF;background-color:#6090A0"> -->
                      <span style="color:#a1c6fa; font-size:24px !important">BEM VINDO</span>
                      <div style="height:10px"></div>
                    </td>
                  </tr>
                </tbody>
              </table>

            </td>

          </tr>
        </tbody>
      </table>

    </div>
    <!-- End Modal Header -->
  </div>

</template>

<script type="text/javascript">
import store_usuario from "../../store/store_usuario";
import { formatNumber } from "../../services/funcoes";
import store_Login from './store_Login';

export default {
  name: "LoginCabecalho",

  data() {
    return {
      formatNumber          : formatNumber,
      store_usuario         : store_usuario,
      store_Login           : store_Login,
    };
  },
  
  mounted() {
    //console.log("2 - ImagemCapaCaminhoCompleto", this.store_usuario.user);
  },

  methods: {
    FechaDialogo() {
      this.store_Login.dialog_flutuante = false;
    },
  }

};
</script>

<style scoped>

.title-value {
  font-size: 18px !important;
  color: #ffffff;
}

</style>
