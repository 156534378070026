<template>
  <div id="LoginRegistrouSemConfirmacao" style="min-height:450px" v-bind:style="{ 'background-color':COR_SECUNDARIA }">

    <v-layout row wrap class="modal-wrapper-owner align-center">

    <!-- Modal Content -->
    <v-layout row wrap class="modal-wrapper w-100 ml-0 pt-4">

        <!-- Modal Content -->
        <v-layout row wrap class="modal-container w-100 ml-0 pt-4 px-4 mb-3">
          <v-container class="w-100 py-6 rounded white">
            <h2
              align="center"
              style="color: #5cb460"
              class="intro font-weight-bold"
            >
              Cadastro realizado com sucesso!
            </h2>
        <div
          class="container-check d-flex justify-center align-center mt-6 mx-auto"
        >
          <v-icon class="icon-check" color="green">mdi-check-bold</v-icon>
        </div>
          </v-container>

          <v-container class="pt-7 justify-center align-center ">
            <!-- <p class="text">
              Você acaba de receber um email com o documento de simulação,
            </p> -->
            <p class="text justify-center align-center ">
              Parabéns!<br>
              Seus dados foram cadastrados em nosso aplicativo.<br>
              Assim que os gestores liberarem o seu cadastro, você poderá entrar em nossos sistemas !
            </p>

            <br>

            <!-- Modal Footer -->
            <v-container class="px-4 text-none">
              <v-btn
                class="btn-anexo text-white text-none d-flex justify-center align-center mt-6 mx-auto"
                color="primary"
                v-on:click="store_login.currentTransition='prev';store_login.step='LoginEntrar'"
              >
              <v-icon left dark>mdi-eye</v-icon>
                OK
              </v-btn>

            </v-container>
            <!-- End Modal Footer -->

          </v-container>
        </v-layout>
        <!-- End Modal Content -->
    </v-layout>
    </v-layout>

  </div>
</template>

<script type="text/javascript">

import store_login from "./store_Login";

export default {
  name: "LoginRegistrouSemConfirmacao",

  data() {
    return {
      store_login : store_login,
    };
  },

  mounted() {
    
  }

};
</script>

<style scoped lang="scss">
*,
html {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
  list-style: none;
  font-size: 16px !important;
  text-decoration: none !important;
}

.button-container {
  background: #e8e8e8;
  z-index: 999;
}

.btn-login {
  width: 150px;
}

.input-text {
  ::v-deep {
    .v-text-field {
      color: chocolate;
    }
    .v-text-field__details {
      margin-top: "2px" !important;
    }
  }
}

.error--text {
  font-size: 12px !important;
}

.v-counter {
  font-size: 12px !important;
}

.v-messages__message {
  padding-top: {
    font-size: 12px !important;
  }
}

$text-field-details: 12px !important;

.v-input__slot {
  margin-bottom: 0px !important;
  background: aqua !important;
}

.v-text-field__details {
  font-family: "Montserrat", sans-serif !important;
  font-size: 12px !important;
}

</style>


<style scoped>
*,
html {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
  list-style: none;
  font-size: 16px !important;
  text-decoration: none !important;
}

.modal-wrapper {
  overflow-y: auto;
  height: 216px;
}

.button-container {
  background: #e8e8e8;
  z-index: 999;
}

.v-btn {
  width: 150px;
}

.modal-wrapper {
  overflow-y: auto;
  height: 460px;
}

.modal-wrapper-owner {
  overflow-y: auto;
  height: calc(100vh);
}

</style>
